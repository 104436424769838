import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import typography from "../../styles/typography"
import { Header3, BodyMedium } from "../typography"

const Wrapper: any = styled.div`
  display: flex;

  padding: 40px;
  margin: 40px auto 0;

  max-width: 1360px;

  color: ${colors.creamLight};

  .review-title {
    display: flex;
    flex: 0 0 45%;
    justify-content: center;
  }

  .review-content {
    display: flex;
    flex-direction: column;
    flex: 0 0 55%;

    max-width: 560px;
  }

  span {
    ${typography.metaXLarge.desktop};
  }

  span.name {
    margin-bottom: 20px;
  }

  span.product {
    margin-top: 20px;
  }
`

export default function ReviewCard({ review }) {
  return (
    <Wrapper className="review-card">
      {/* <GatsbyImage image={review.picture.gatsbyImage} alt={review.title} /> */}
      <div className="review-title">
        <Header3>{review.title}</Header3>
      </div>
      <div className="review-content">
        {review.name && <span className="name">{review.name}</span>}
        <BodyMedium>{review.content.content}</BodyMedium>
        {review.purchasedProduct?.productTitle && (
          <span className="product">
            {review.purchasedProduct?.productTitle}
          </span>
        )}
      </div>
    </Wrapper>
  )
}
