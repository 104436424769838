import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import colors from "../styles/colors"
import PageHeader from "../components/common/PageHeader"
import ReviewCard from "../components/common/ReviewCard"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Wrapper = styled.div`
  border-bottom: 1px solid ${colors.creamLight};

  .review-card:last-of-type {
    margin-bottom: 40px;
  }
`

export const query = graphql`
  query ReviewsQuery {
    contentfulReviews {
      reviews {
        id
        title
        name
        purchasedProduct {
          productTitle
        }
        content {
          content
        }
      }
    }
  }
`

export function Head() {
  return <Seo title={"Reviews"} />
}

export default function Reviews({ data }) {
  const reviews = data.contentfulReviews.reviews

  return (
    <Layout>
      <Wrapper>
        <PageHeader
          header="Reviews"
          subheader="Join hundreds of Drømme drinkers who love our botanical elixirs. They love Drømme with ginger, Drømme with soda, Drømme shaken, and Drømme stirred."
          body={null}
          cta="View Reviews"
          onCtaClick={() => window.scrollBy(0, 265)}
        />

        {reviews.map(review => (
          <ReviewCard key={review.id} review={review} />
        ))}
      </Wrapper>
    </Layout>
  )
}
